<template>
  <div class="auth">
    <v-col class="col-md-6 col-lg-4 mx-auto">
      <v-card elevation="2" class="md" :loading="loading">
        <v-card-title>{{ 'Вход' }}</v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-text-field
              v-model="email"
              :name="Math.random()"
              autocomplete="off"
              :rules="[rules.requiredEmail, rules.email]"
              label="E-mail"
              :error-messages="emailErrors"
              @change="requestSended = false"
            ></v-text-field>
            <v-text-field
              v-model="password"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.requiredPass, rules.min]"
              :type="showPass ? 'text' : 'password'"
              :error-messages="passErrors"
              :name="Math.random()"
              autocomplete="off"
              label="Пароль"
              counter
              
              @click:append="showPass = !showPass"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
              :disabled="!valid"
              color="primary"
              class="mr-4"
              @click="login"
            >
              Войти
            </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn
              color="primary"
              router 
              :to="'register'"
              text>
              Регистрация
            </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-col>
  </div>
</template>

<script>

export default {
  data () {
    return {
      email: '',
      password: '',
      requestSended: false,
      valid: true,
      loading: false,
      showPass: false,
      rules: {
        requiredEmail: value => !!value || 'Введите E-mail адрес.',
        requiredPass: value => !!value || 'Введите пароль.',
        min: value => value.length >= 4 || 'Минимум 4 символа',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Не корректный E-mail.'
        },
      },
    }
  },
  watch: {
    email() {
      this.errors = this.errors.filter(e => e.pointer != "email")
    },
    password() {
      this.errors = this.errors.filter(e => e.pointer != "password")
    },
    loginStatus() {
      this.loading = false; 
      if (this.loginStatus == 'success') {
        this.$store.dispatch('init');
        this.$router.push('/analytics');
      }
      if (this.loginStatus == 'loading') {
        this.loading = true;
        
      }
    }
  },
  computed: {
    errors: {
        get() {
          return [this.$store.state.auth.authError];
        },
        set() {
          this.$store.commit('clear_auth_errors')
        }
    },
    passErrors: {
        get () {
          return this.errors.filter(e => e.pointer == "password").map(e => e.message);
        }
    },
    emailErrors: {
        get () {
          return this.errors
            .filter(e => e.pointer == "email")
            .map(e => e.message);
        }
    },
    loginStatus: {
        get () {
          return this.$store.getters.authStatus;
        }
    },

  },
  methods: {
    login () {
      if (this.$refs.form.validate()) {
        this.requestSended = true;
        this.$store.dispatch('login', {email:this.email, password: this.password})
      }
    },
  },
}
</script>
